import './general.css';
import './Mp6Waiting.css';

const Mp6Waiting = () => {
    return (
        <section className="waiting">
            <div className="sec-title waiting__title">Обновленный бойцовский клуб<br />ждет тебя!</div>
        </section>
    );
};

export default Mp6Waiting;