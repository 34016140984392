import './general.css';
import './Mp7News.css';

import CalendarIcon from '../../img/calendar-mini.png';

const Mp7News = () => {

    const newsInfoArr = [
        {
            id: 1,
            title: 'Начался новый учебный год',
            date: '01 сентября 2023 г.',
            text: 'Начался новый учебный год, поэтому пора записываться в клубы единоборств и приходить заниматься.Так что приходите скорее.',
        },
        {
            id: 2,
            title: 'Сайт в разработке',
            date: '25 октября 2023 г.',
            text: 'Тут будет много супер интересного, следите за новостями',
        },
        {
            id: 3,
            title: 'Скоро аттестация',
            date: '01 ноября 2023 г.',
            text: 'Начинаем готовиться, чтобы сдать все нормативы наилучшим образом, так что нельзя пропускать занятия и мало заниматься.',
        },
        {
            id: 4,
            title: 'Разработка сайта продолажется',
            date: '03 ноября 2023 г.',
            text: 'Полным холодом идет разработка сайта',
        },
    ];

    const newsItemArr = newsInfoArr.map(item => {
        return (
            <div className="news__item" key={item.id}>
                <div className="news__orange-line"></div>
                <div className="news__date">
                    <img src={CalendarIcon} alt="calendar-pic" />
                    <div className="news__date-date">{item.date}</div>
                </div>
                <div className="news__item-title">{item.title}</div>
                <div className="news__item__text">{item.text}</div>
            </div>
        );
    });

    return (
        <section className="news" id="news">

            <div className="container">
                <div className="sec-title news__title">Новости</div>

                <div className="news__wrapper">

                    {newsItemArr}

                </div>

                <button className="btn btn-outline">Читать больше</button>
            </div>

        </section >
    );
};

export default Mp7News; 