import { useEffect, useState } from "react";
import Mp1Promo from "./Mp1Promo";
import Mp2Mission from "./Mp2Mission";
import Mp3Clubs from "./Mp3Clubs";
import Mp4Values from "./Mp4Values";
import Mp5Competitions from "./Mp5Competitions";
import Mp6Waiting from "./Mp6Waiting";
import Mp7News from "./Mp7News";
import Mp8Join from "./Mp8Join";
import Mp9Footer from "./Mp9Footer";
import Pageup from "../../components/Pageup";

const MainPage = () => {

    const [pageupActive, setPageupActive] = useState(false);

    useEffect(() => {
        document.addEventListener('scroll', () => {
            if (document.documentElement.scrollTop > 570) {
                setPageupActive(true);
            } else {
                setPageupActive(false);
            }
        });
    }, []);

    return (
        <>
            <Mp1Promo />
            <Mp2Mission />
            <Mp3Clubs />
            <Mp4Values />
            <Mp5Competitions />
            <Mp6Waiting />
            <Mp7News />
            <Mp8Join />
            <Mp9Footer />
            <Pageup pageupActive={pageupActive} />
        </>
    );
};

export default MainPage;