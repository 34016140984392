// import logo from './logo.svg';
import './App.css';
import MainPage from './pages/MainPage/MainPage';

function App() {
  return (
    <>
      <MainPage onScroll={() => console.log('00')} />
    </>
  );
}

export default App;
