import './general.css';
import './Mp2Mission.css';

import MissionImg1 from '../../img/mission1.png';
import MissionImg2 from '../../img/mission2.png';
import MissionImg3 from '../../img/mission3.png';

const Mp2Mission = () => {

    const missionItemsInfoArr = [
        {
            id: 1,
            title: 'Направление деятельности',
            text: 'Организация системного подхода в области физической культуры и спорта посредством информационных технологий',
            img: MissionImg1
        },
        {
            id: 2,
            title: 'Наша цель',
            text: 'Создание информационной платформы',
            img: MissionImg2
        },
        {
            id: 3,
            title: 'Наша задача',
            text: 'Актуализировать тему единой базы спортсменов',
            img: MissionImg3
        }
    ];

    const missionItemsArr = missionItemsInfoArr.map(item => {
        return (
            <div className="mission__item" key={item.id}>
                <div className="mission__item__img">
                    <img src={item.img} alt="mission1" />
                </div>
                <div className="mission__item__title">{item.title}</div>
                <div className="mission__item__text">{item.text}</div>
            </div>
        );
    });

    return (
        <section className="misson">
            <div className="container">

                <div className="misson__our-mission">
                    <div className="sec-title mission__our-mission__title">Наша миссия</div>
                    <div className="mission__our-mission__text">
                        Усовершенствование стандартов оценки качества в<br />единоборствах
                    </div>
                </div>

                <div className="mission__wrapper">

                    {missionItemsArr}

                </div>

            </div>
        </section>
    );
};


export default Mp2Mission;
