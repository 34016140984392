import './general.css';
import './Mp9Footer.css';

import VkIcon from '../../img/vk.png';
import SiteIcon from '../../img/site.png';

const Mp9Footer = () => {
    return (
        <section className="footer" >
            <div className="container">
                <div className="sec-title footer__title">Свяжитесь с нами любым удобным способом</div>
                <div className="footer__tel">89025115100</div>

                <div className="footer__soc-contacts">

                    <div className="soc-contacts_item">
                        <a href="https://xn--d1aboskml.xn--p1ai/" target="_blank" className="" rel="noreferrer">
                            <img src={SiteIcon} alt="site" target="_blank" className="soc-img" />
                        </a>
                    </div>

                    <div className="soc-contacts_item">
                        <a href="https://vk.com/kedfort" target="_blank" className="" rel="noreferrer">
                            <img src={VkIcon} alt="vk" className="soc-img" />
                        </a>
                    </div>

                </div>

                <div className="footer__line"></div>

                <div className="footer_copyright">&#169; 2018 Все права защищены. Союз бойцов</div>

            </div>
        </section >
    );
};

export default Mp9Footer;