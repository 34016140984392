import './general.css';
import './Mp8Join.css';

const Mp8Join = () => {
    return (
        <section className="join" id="join" >
            <div className="container">
                <div className="sec-title join__title">Вступить в союз</div>
                <button className="btn">Заполнить анкету</button>
            </div>
        </section >
    );
};

export default Mp8Join;