import './general.css';
import './Mp5Competitions.css';

import Сomp_ex1 from '../../img/comp_ex1.png';

const Mp5Competitions = () => {

    const competitionsInfoArr = [
        {
            id: 1,
            title: 'Соревнование по самбо',
            city: 'Иркутск',
            date: '25 октября 2023 г.',
            img: Сomp_ex1,
        },
        {
            id: 2,
            title: 'Соревнование по боям без правил',
            city: 'Ангарск',
            date: '25 ноября 2023 г.',
            img: Сomp_ex1,
        },
        {
            id: 3,
            title: 'Соревнование по рукопашному бою',
            city: 'Иркутск',
            date: '15 декабря 2023 г.',
            img: Сomp_ex1,
        },
    ];

    const competitionsItemArr = competitionsInfoArr.map(item => {
        return (
            <div className="competition__item" key={item.id}>
                <div className="competitions__item__img"><img src={item.img} alt="competition" /></div>
                <div className="competition__title_box">
                    <div className="competitions__item__title">{item.title}</div>
                </div>
                <div className="competitions__item__city">{item.city}</div>
                <div className="competitions__item__line"></div>
                <div className="competitions__item__date">{item.date}</div>
            </div>
        );
    });

    return (

        <section className="competitions" id="competition">
            <div className="container">
                <div className="sec-title competitions__title">Ближайшие<br />соревнования</div>

                <div className="competitions__wrapper">

                    {competitionsItemArr}

                </div>

                <button className="btn btn-outline">Смотреть все соревнования</button>

            </div>

        </section>
    );
};

export default Mp5Competitions;