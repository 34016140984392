import './general.css';
import './Mp3Clubs.css';

import LogoKedfort from '../../img/logo_kedfort.png';
import ClubCard from '../../components/ClubCard';

const Mp3Clubs = () => {

    const clubsInfoArr = [
        {
            id: 1,
            name: 'Клуб единоборств КЕДФОРТ',
            description: 'Форт - это сомкнутое надежное укрепление, каким мы хотели бы видеть наш клуб. Это Формула Оптимизма, Решимости и Терпения.Вместе мы будем Формировать Опыт Рациональной Техники тренировки духа и тела.',
            img: LogoKedfort
        },
    ];

    const clubsArr = clubsInfoArr.map(item => {
        return (
            <ClubCard clubInfo={item} key={item.id} />
        );
    });

    return (
        <section className="clubs" id="clubs">
            <div className="container">
                <div className="sec-title clubs__title">Организации</div>

                <div className="clubs__wrapper">

                    {clubsArr}

                </div>

            </div>
        </section>
    );
};


export default Mp3Clubs;
