import './general.css';
import './Mp1Promo.css';
import './burgerMenu.css';

import BurgerMenuImg from '../../img/burger-menu.png';
import { useState } from 'react';

const Mp1Promo = () => {

    const menuItemsInfoArr = [
        {
            id: 1,
            name: 'Организации',
            href: '#clubs',
        },
        {
            id: 2,
            name: 'Соревнования',
            href: '#competition',
        },
        {
            id: 3,
            name: 'Новости',
            href: '#news',
        },
        {
            id: 4,
            name: 'Вступить в союз',
            href: '#join',
        },
    ];

    const menuItemsArr = menuItemsInfoArr.map(item => {
        return (
            <li key={item.id}><a href={item.href} className="menu__link">{item.name}</a></li>
        );
    });

    return (
        <>
            <section className="promo" id="up">
                <div className="header1">
                </div>

                <div className="container">

                    <div className="header">

                        <div className="header__city">Иркутск</div>

                        <ul className="menu">
                            {menuItemsArr}
                        </ul>

                        <div className="header__account__section">
                            <div className="header__account" id="account">Кабинет бойца</div>
                        </div>

                    </div>

                    <div className="promo__title">
                        Мы приглашаем вступить в союз<br />спортивные организации<br />единоборств
                    </div>

                </div>

            </section>

            <BurgerMenu menuItemsInfoArr={menuItemsInfoArr} />

        </>
    );
};



const BurgerMenu = ({ menuItemsInfoArr }) => {

    const [burgerMenuActive, setBurgerMenuActive] = useState(false);

    const menuItemsArr = menuItemsInfoArr.map(item => {
        return (
            <div className="burger-menu-item" key={item.id}>
                <a href={item.href} className="menu__link" onClick={() => setBurgerMenuActive(false)}>{item.name}</a>
            </div>
        );
    });

    let burgerMenuClasses = burgerMenuActive ? "burger-block-main burger-menu-view" : "burger-block-main";

    return (
        <>
            <div className="burger-menu-button" onClick={() => setBurgerMenuActive(true)}>
                <img src={BurgerMenuImg} alt="burger-menu" />
            </div>

            <div className={burgerMenuClasses}>
                <div className="burger-block">
                    <div className="close">
                        <div className="burger-menu-close" onClick={() => setBurgerMenuActive(false)}>&times;</div>
                    </div>
                    <div className="burger-menu">
                        <div className="burger-menu-account">Кабинет бойца</div>
                        {menuItemsArr}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Mp1Promo;