import '../pages/MainPage/general.css';
import './ClubCard.css';

// import LogoKedfort from '../img/logo_kedfort.png';
// import EyeIcon from '../img/eye.png';

const ClubCard = ({ clubInfo }) => {
    return (
        <div className="clubs-item">
            <div className="club-item__img">
                <img src={clubInfo.img} alt="logo_kedfort" className="logo_kedfort_img" />
            </div>
            <div className="club-item__title">{clubInfo.name}</div>
            <div className="club-item__text">{clubInfo.description}</div>
            <div className="club_link">
                {/* <img src={EyeIcon} alt="eye" /> */}
                <a href="#">Заявка на бесплатную тренировку</a>
            </div>
        </div>
    );
};

export default ClubCard;
