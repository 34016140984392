import './Pageup.css';

import PageupImg from '../img/pageup.png';

const Pageup = ({ pageupActive }) => {

    const pageupClasses = pageupActive ? "pageup pageup-display" : "pageup";

    return (
        <a href="#up" className={pageupClasses}>
            <img src={PageupImg} alt="pageup" />
        </a>
    );
};

export default Pageup;