import './general.css';
import './Mp4Values.css';

import ValueIcon from '../../img/value_img.png';

const Mp4Values = () => {
    return (
        <section className="values">
            <div className="container">
                <div className="sec-title values__title">Наши ценности</div>

                <div className="values__wrapper">

                    <div className="value__column">

                        <div className="values-item">
                            <div className="values-item__img">
                                <img src={ValueIcon} alt="value" className="values-item_img__pic" />
                            </div>
                            <div className="values-item__text">Усовершенствование</div>
                        </div>

                        <div className="values-item">
                            <div className="values-item__img">
                                <img src={ValueIcon} alt="value" className="values-item_img__pic" />
                            </div>
                            <div className="values-item__text">Обогащение</div>
                        </div>

                        <div className="values-item">
                            <div className="values-item__img">
                                <img src={ValueIcon} alt="value" className="values-item_img__pic" />
                            </div>
                            <div className="values-item__text">Улучшение</div>
                        </div>

                    </div>

                    <div className="value__column">

                        <div className="values-item">
                            <div className="values-item__img">
                                <img src={ValueIcon} alt="value" className="values-item_img__pic" />
                            </div>
                            <div className="values-item__text">Формирование</div>
                        </div>

                        <div className="values-item">
                            <div className="values-item__img">
                                <img src={ValueIcon} alt="value" className="values-item_img__pic" />
                            </div>
                            <div className="values-item__text">Содействие</div>
                        </div>

                        <div className="values-item">
                            <div className="values-item__img">
                                <img src={ValueIcon} alt="value" className="values-item_img__pic" />
                            </div>
                            <div className="values-item__text">Развитие</div>
                        </div>

                    </div>

                </div>

            </div>

        </section>

    );
};

export default Mp4Values;